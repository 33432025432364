import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32907f5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add-file-and-info" }
const _hoisted_2 = {
  key: 0,
  class: "input-area pt-5"
}
const _hoisted_3 = { class: "input-wrapper top" }
const _hoisted_4 = { class: "input-row" }
const _hoisted_5 = { class: "input-label" }
const _hoisted_6 = { class: "required-label" }
const _hoisted_7 = { class: "input-row" }
const _hoisted_8 = { class: "input-label" }
const _hoisted_9 = { class: "section-header mt-5" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "input-wrapper bottom" }
const _hoisted_12 = { class: "two-input-row" }
const _hoisted_13 = { class: "w-full" }
const _hoisted_14 = { class: "input-label" }
const _hoisted_15 = { class: "two-input-row" }
const _hoisted_16 = { class: "w-full" }
const _hoisted_17 = { class: "input-label" }
const _hoisted_18 = { class: "two-input-row" }
const _hoisted_19 = { class: "haft-line" }
const _hoisted_20 = { class: "input-label" }
const _hoisted_21 = { class: "haft-line" }
const _hoisted_22 = { class: "input-label" }
const _hoisted_23 = { class: "two-input-row" }
const _hoisted_24 = { class: "haft-line" }
const _hoisted_25 = { class: "input-label" }
const _hoisted_26 = { class: "haft-line" }
const _hoisted_27 = { class: "input-label" }
const _hoisted_28 = { class: "two-input-row" }
const _hoisted_29 = { class: "haft-line" }
const _hoisted_30 = { class: "input-label" }
const _hoisted_31 = { class: "haft-line" }
const _hoisted_32 = { class: "input-label" }
const _hoisted_33 = { class: "two-input-row" }
const _hoisted_34 = { class: "w-full" }
const _hoisted_35 = { class: "input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilePreview = _resolveComponent("FilePreview")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_CustomDatepicker = _resolveComponent("CustomDatepicker")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["file-upload-area", { 'has-file': _ctx.localFiles.length > 0 }])
    }, [
      (_ctx.localFiles.length > 0)
        ? (_openBlock(), _createBlock(_component_FilePreview, {
            key: 0,
            files: _ctx.localFiles,
            "onUpdate:files": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFiles) = $event)),
            selectedIdx: _ctx.selectedIndex,
            "onUpdate:selectedIdx": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedIndex) = $event))
          }, null, 8, ["files", "selectedIdx"]))
        : _createCommentVNode("", true),
      _createVNode(_component_FileUpload, {
        files: _ctx.localFiles,
        "onUpdate:files": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localFiles) = $event)),
        onSelectedAt: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedIndex = $event)),
        "file-type": _ctx.fileType
      }, null, 8, ["files", "file-type"])
    ], 2),
    (_ctx.localFiles.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("common.requiredText")), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("template.templateTitle")), 1)
                ]),
                _createVNode(_component_ValidationTextField, {
                  rules: "required",
                  message: {
                error_required: _ctx.$t('errors.required'),
              },
                  value: _ctx.localContractBasicInfo.title,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localContractBasicInfo.title) = $event))
                }, null, 8, ["message", "value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("common.optionalField") + " " + _ctx.$t("contract.partnerName")), 1),
                _createVNode(_component_ValidationTextField, {
                  value: _ctx.localContractBasicInfo.contractPartnerName,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localContractBasicInfo.contractPartnerName) = $event)),
                  placeholder: _ctx.$t('contract.partnerNameHint')
                }, null, 8, ["value", "placeholder"])
              ])
            ]),
            (!_ctx.isDocxTemplate)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("contractSummary.advancedOption")), 1),
                    _createElementVNode("img", {
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isShowAdvanced = !_ctx.isShowAdvanced)),
                      class: _normalizeClass(["mt-1", { open: _ctx.isShowAdvanced }]),
                      src: require('icons/icon_dropdown.svg'),
                      alt: "dropdown icon"
                    }, null, 10, _hoisted_10)
                  ]),
                  _createVNode(_Transition, { name: "expanded-area" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.contractSummary")), 1),
                            _createVNode(_component_ValidationTextField, {
                              value: _ctx.localContractDetailInfo.contractOverview,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localContractDetailInfo.contractOverview) = $event)),
                              "max-length": 200
                            }, null, 8, ["value"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.contractExpiredTime")), 1),
                            _createVNode(_component_CustomDatepicker, {
                              class: "date-picker",
                              type: "text",
                              placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                              "is-black": true,
                              value: 
                      _ctx.localContractDetailInfo.contractConclusionDate
                    ,
                              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((
                      _ctx.localContractDetailInfo.contractConclusionDate
                    ) = $event))
                            }, null, 8, ["placeholder", "value"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.contractStart")), 1),
                            _createVNode(_component_CustomDatepicker, {
                              class: "date-picker",
                              placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                              "is-black": true,
                              "upper-limit-date": 
                      _ctx.localContractDetailInfo.contractEndDate
                        ? new Date(_ctx.localContractDetailInfo.contractEndDate)
                        : null
                    ,
                              value: _ctx.localContractDetailInfo.contractStartDate,
                              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localContractDetailInfo.contractStartDate) = $event))
                            }, null, 8, ["placeholder", "upper-limit-date", "value"])
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.contractEnd")), 1),
                            _createVNode(_component_CustomDatepicker, {
                              class: "date-picker",
                              type: "text",
                              placeholder: _ctx.$t('contractSummary.datePickerPlaceholder'),
                              "is-black": true,
                              "lower-limit-date": 
                      _ctx.localContractDetailInfo.contractStartDate
                        ? new Date(_ctx.localContractDetailInfo.contractStartDate)
                        : null
                    ,
                              value: _ctx.localContractDetailInfo.contractEndDate,
                              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localContractDetailInfo.contractEndDate) = $event))
                            }, null, 8, ["placeholder", "lower-limit-date", "value"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.cancelExpiredTime")), 1),
                            _createVNode(_component_ValidationTextField, {
                              value: 
                      _ctx.localContractDetailInfo.terminationNoticePeriod
                    ,
                              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((
                      _ctx.localContractDetailInfo.terminationNoticePeriod
                    ) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.autoUpdate")), 1),
                            _createVNode(_component_Dropdown, {
                              "place-holder": _ctx.$t('common.selectPlaceHolder'),
                              options: _ctx.automaticOptions,
                              "default-option": 
                      _ctx.localContractDetailInfo.isAutomaticallyUpdated
                    ,
                              value: 
                      _ctx.localContractDetailInfo.isAutomaticallyUpdated
                    ,
                              "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((
                      _ctx.localContractDetailInfo.isAutomaticallyUpdated
                    ) = $event))
                            }, null, 8, ["place-holder", "options", "default-option", "value"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.controlNumber")), 1),
                            _createVNode(_component_ValidationTextField, {
                              "max-length": 100,
                              message: {
                      error_format_control_number: _ctx.$t('errors.formatControlNumber'),
                    },
                              rules: "controlNumber",
                              value: _ctx.localContractDetailInfo.controlNumber,
                              "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localContractDetailInfo.controlNumber) = $event))
                            }, null, 8, ["message", "value"])
                          ]),
                          _createElementVNode("div", _hoisted_31, [
                            _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.transactionAmount")), 1),
                            _createVNode(_component_ValidationTextField, {
                              type: "text",
                              value: 
                      _ctx.localContractDetailInfo.transactionAmount
                    ,
                              "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((
                      _ctx.localContractDetailInfo.transactionAmount
                    ) = $event)),
                              valueModifiers: { number: true },
                              "max-length": 10,
                              rules: "number",
                              message: {
                      error_format_number: _ctx.$t('errors.formatNumber'),
                    }
                            }, null, 8, ["value", "message"])
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("div", _hoisted_34, [
                            _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$t("common.optionalField") +
                      " " +
                      _ctx.$t("contractSummary.freeText") +
                      " " +
                      _ctx.$t("contractSummary.numberOfFreeText")), 1),
                            _createVNode(_component_ValidationTextField, {
                              value: _ctx.localContractDetailInfo.freeText,
                              "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localContractDetailInfo.freeText) = $event)),
                              "is-multiline": true,
                              required: "max:2000",
                              "max-length": "2000",
                              class: "multiline-input"
                            }, null, 8, ["value"])
                          ])
                        ])
                      ], 512), [
                        [_vShow, _ctx.isShowAdvanced]
                      ])
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}