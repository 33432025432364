
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  watch,
  PropType,
} from "vue";
import FilePreview from "@/components/parts/FilePreview.vue";
import FileUpload from "@/components/parts/FileUpload.vue";
import CustomDatepicker from "@/components/atomics/CustomDatepicker.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { TemplateInformation as ContractDetailInfo } from "@/models/contracts/Information";
import { FileInfo } from "@/models/contracts/FileInfo";

import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { getFileExtension } from "@/lib/utility/common";
import router from "@/router";

interface ContractBasicInfo {
  title: string;
  contractPartnerName: string;
}

export default defineComponent({
  name: "TemplateFileAndInfo",
  components: {
    FileUpload,
    FilePreview,
    CustomDatepicker,
    Dropdown,
    ValidationTextField,
  },
  emits: [
    "update:is-docx-template",
    "update:contractBasicInfo",
    "update:contractDetailInfo",
    "update:files",
  ],
  props: {
    files: {
      type: Array as PropType<Array<FileInfo>>,
      required: true,
    },
    contractBasicInfo: {
      type: Object as PropType<ContractBasicInfo>,
      required: true,
    },
    contractDetailInfo: {
      type: Object as PropType<ContractDetailInfo>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const state = reactive({
      selectedIndex: 0,
      isShowAdvanced: true,
    });

    const store = useStore();

    const automaticOptions = [
      {
        id: "true",
        name: i18n.t("template.updateOption1"),
      },
      {
        id: "false",
        name: i18n.t("template.updateOption2"),
      },
    ];

    const isDocxTemplate = computed(() =>
      props.files.some(
        (file: any) =>
          getFileExtension(file?.name) == "docx" ||
          getFileExtension(file?.name) == "doc"
      )
    );

    const fileType = computed(() => {
      // if (props.files.length == 0) return ".docx,.doc,pdf";
      // if (isDocxTemplate.value) return ".docx,.doc,pdf";
      return "pdf";
    });

    watch(isDocxTemplate, (value) => {
      if (value) emit("update:is-docx-template", true);
      else emit("update:is-docx-template", false);
    });

    const localContractBasicInfo = computed({
      get(): ContractBasicInfo {
        return props.contractBasicInfo;
      },

      set(value: ContractBasicInfo) {
        emit("update:contractBasicInfo", value);
      },
    });

    const localContractDetailInfo = computed({
      get(): ContractDetailInfo {
        return props.contractDetailInfo;
      },

      set(value: ContractDetailInfo) {
        emit("update:contractDetailInfo", value);
      },
    });

    const localFiles = computed({
      get(): FileInfo[] {
        return props.files;
      },

      set(value: FileInfo[]) {
        emit("update:files", value);
      },
    });

    return {
      ...toRefs(state),
      automaticOptions,
      localFiles,
      localContractBasicInfo,
      localContractDetailInfo,
      isDocxTemplate,
      fileType,
    };
  },
});
